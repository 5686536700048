<template>
  <div class="fromList">
      <div class="flTittle">{{fromTittle}}</div>
      <div v-for="(i,index) in arr" :key="index">
            <div class="fromFild" v-if="i.formType == 1 || i.formType == 5">
                <span>{{i.desc}}</span>
                <span>{{i.newVal}}</span>
            </div>
            <div class="richText" v-else-if="i.formType == 2">
                <div>{{i.desc}}</div>
                <div>
                    {{i.newVal}}
                </div>
            </div>
            <div class="richPhoto" v-else-if="i.formType == 7">
                <div>{{i.desc}}</div>
                <img :src="i.newVal" alt="">
            </div>
      </div>
      
  </div>
</template>

<script>
export default {
    name:"fromlist",
    props:['fromlist','fromTittle'],
    data(){
        return{
            arr:[],
            textField:[],
            img:[]
        }
    },
    watch:{
        fromlist(){
            this.fromlist = this.fromlist
        }
    },
    computed:{},
    components:{},
    created(){
        this.init()
    },
    mounted(){
        if(this.$store.state.jxbApp){
            window.location.href=`ssptitle://${this.fromTittle}`
        }else{
            document.title = this.fromTittle
        }
        
    },
    methods:{
        init(){
            // console.log(this.fromlist)
            let arr = JSON.parse(this.fromlist)
            let list = arr.sort(this.compare('weight'))
            list.map((item)=>{
                if(!item.newVal){
                    item.newVal = '----'
                }
            })
            this.arr = list
        },
        // 排序
        compare (property) {
            return (a, b) => {
                var value1 = a[property]
                var value2 = b[property]
                return value2 - value1
            }
        },
    }
}
</script>

<style lang="less" scoped>
.fromList{
    background-image: linear-gradient(#E0EEF4 5%,#fff 10%, #fff 80%);
    // border-radius: .444444rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 .355556rem;
    box-sizing: border-box;
    .flTittle{
        color: #013D80;
        font-weight: 600;
        font-size: .497778rem;
        margin-top: .631111rem;
        // margin-left: .413333rem;
        text-align: left;
        margin-bottom: 1.137778rem;
    }
    .fromFild{
        height: .88rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: .008889rem solid #efecec;
        margin-top: .533333rem;
        span:nth-child(1){
            color: #67707A;
            font-size: .355556rem;
        }
        span:nth-child(2){
            color: #000;
            font-size: .355556rem;
        }
    }
    .richText{
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: .008889rem solid #efecec;
        flex-direction: column;
        text-align: left;
        div:nth-child(1){
            color: #67707A;
            font-size: .355556rem;
            margin-top: .284444rem;
        }
        div:nth-child(2){
            color: #000;
            font-size: .355556rem;
            margin-top: .284444rem;
            margin-bottom: .56rem;
        }
    }
    .richPhoto{
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: .008889rem solid #efecec;
        flex-direction: column;
        text-align: left;
        div:nth-child(1){
            color: #67707A;
            font-size: .355556rem;
            margin-top: .284444rem;
        }
        // div:nth-child(2){
        //     color: #000;
        //     font-size: .355556rem;
        //     margin-top: .284444rem;
        //     margin-bottom: .56rem;
        // }
        img{
            width: 9.235556rem;
            height: 6.915556rem;
            // background: lightcoral;
            margin: .355556rem auto;
        }
    }
}
</style>